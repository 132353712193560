import React from "react";
import { Card, Divider } from "antd";

export function Testimonial({ testimonial }) {
  return (
    <Card title={testimonial.title} className={"testimonial-card"}>
      <div dangerouslySetInnerHTML={{ __html: testimonial.content }}></div>
      <a href={testimonial.url} target="_blank" rel="noreferrer noopener">{testimonial.url}</a>
    </Card>
  );
}
